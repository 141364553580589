import { SiteInit } from './assets/siteinit';
import { SA } from './assets/scrollAnimation';
import { CSSAcdn } from './assets/accordion';
import { gsap } from "gsap";

/*
SiteInit option default
{
	menuBtn: '#menuBtn', //メニューボタンのセレクタ
	menu: '#menu', //メニューのセレクタ
	menuBtnSwitch: 'on', //メニューを開く時にメニューボタンに追加するクラス
	menuSwitch: 'show', //メニューを開く時にメニューに追加するクラス
	anchorIgnore: '.noscroll', //スムーススクロールを適用しない要素のセレクタ
	anchorSpeed: 300, //スムーススクロールのスピード
	anchorCallback: null, //スムーススクロール時に実行するコールバック関数
}
メソッド
siteInit.anchorScroll(href, cb); // hrefまでスムーススクロールさせる。hrefはセレクタorDOMオブジェクト cbはコールバック
siteInit.menuToggle() // メニューの開閉を切り替える（メニューボタンとメニューのクラスを切り替える）
siteInit.menuOpen() // メニューを開く（メニューボタンとメニューに開く時用のクラスをセットする）
siteInit.menuClose() // メニューを閉じる（メニューボタンとメニューの開く時用のクラスを削除する）
*/

const menuCloseBtn = document.getElementById('menuClose');
const overlay = document.getElementById('overlay');
const searchOpenBtns = document.querySelectorAll('.searchBoxOpen');
const searchCloseBtn = document.getElementById('searchBoxClose');
const searchBox = document.getElementById('searchBox');

const siteInit = new SiteInit({
	callback: {
		anchor: () => {
			siteInit.menuClose(); //スムーススクロール時にメニューを閉じる処理
		},
		menuOpen: () => {
			overlay.classList.add('show');
			menuCloseBtn.classList.add('on');
		},
		menuClose: () => {
			overlay.classList.remove('show');
			menuCloseBtn.classList.remove('on');
		},
	}
});

menuCloseBtn.addEventListener('click', () => siteInit.menuClose(), false);

searchOpenBtns.forEach((el) => {
	el.addEventListener('click', () => {
		searchBox.classList.add('show');
		overlay.classList.add('show');
	}, false);
});

searchCloseBtn.addEventListener('click', () => {
	searchBox.classList.remove('show');
	overlay.classList.remove('show');
}, false);

overlay.addEventListener('click', () => {
	siteInit.menuClose()
	searchBox.classList.remove('show');
}, false);


/*
ScrollAnimation option default
{
	selector: '.anm', //スクロールアニメーションを適用する要素のセレクタ
	margin: window.innerHeight * 0.2, //スクロールアニメーションを適用する要素を表示させる位置（デフォルトは画面の一番下から20%の位置）
	showClass: 'show', //スクロールアニメーションを適用する要素が表示位置に来た時に追加するクラス
}
*/

const sa = new SA();


/*
CSSAcdn option default
{
	switchClass: 'open', //開く時に追加するクラス名
}
*/

const acdn = new CSSAcdn();
const btns = document.querySelectorAll('.acdnButton');
const acdnContents = document.querySelectorAll('.acdnContents');
const catItems = document.querySelectorAll('.acdnParent');

for (let i = 0; i < acdnContents.length; i++) {
	acdnContents[i].classList.add('acdn-active');
}

for (let i = 0; i < btns.length; i++) {
	const btn = btns[i]
	btn.addEventListener('click', (e) => {
		let target;
		if (btn.getAttribute('data-acdn-target')) {
			target = document.querySelector(btn.getAttribute('data-acdn-target'));
		} else {
			target = e.currentTarget.nextElementSibling;
		}
		acdn.toggle(target);
		btn.classList.toggle('on');
	}, false);
}

for (let i = 0; i < catItems.length; i++) {
	const item = catItems[i];

	item.addEventListener('mouseenter', (e) => {
		let btn = item.querySelector('.acdnButton');
		let target = item.querySelector('.acdnContents');
		acdn.open(target);
		btn.classList.add('on');
	}, false);

	item.addEventListener('mouseleave', (e) => {
		let btn = item.querySelector('.acdnButton');
		let target = item.querySelector('.acdnContents');
		acdn.close(target);
		btn.classList.remove('on');
	}, false);
}

const circleBtn = function (el) {
	const btn = el;
	const elms = {
		svg1: btn.querySelector('.circleBtn__border'),
		svg2: btn.querySelector('.circleBtn__progress'),
		label: btn.querySelector('.circleBtn__text'),
	};
	const offset = function (el) {
		const box = el.getBoundingClientRect();
		const docElem = document.documentElement;
		return {
			top: box.top + window.pageYOffset - docElem.clientTop,
			left: box.left + window.pageXOffset - docElem.clientLeft
		};
	}

	btn.addEventListener('mouseenter', function () {
		btn.classList.add('is-over', 'is-loaded');
	});

	btn.addEventListener('mouseleave', function () {
		btn.classList.remove('is-over');
		gsap.to([elms.svg1, elms.svg2, elms.label], {
			x: 0,
			y: 0,
		});

	});

	btn.addEventListener('mousemove', function (e) {
		if (!btn.classList.contains('is-over')) {
			return false;
		}
		var h = btn.offsetHeight;
		var w = btn.offsetWidth;
		var x = e.pageX - offset(btn).left;
		var y = e.pageY - offset(btn).top;

		gsap.to([elms.svg1, elms.svg2], {
			x: ((w / 2) - x) * -0.2,
			y: ((h / 2) - y) * -0.2,
		});

		gsap.to(elms.label, {
			x: ((w / 2) - x) * -0.1,
			y: ((h / 2) - y) * -0.1,
		});
	});
}

var circleBtns = document.querySelectorAll('.circleBtn');
circleBtns.forEach((el) => {
	circleBtn(el);
});
